import { ref } from 'vue'
import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'

export type Vet = {
  id: number
  name: string
  email: string
  phone: string
  address: string
  city: string
  state: string
  zip: string
  country: string
  logoUrl: string
  profileComplete: boolean
}

export const useVetStore = defineStore('vet', () => {
  const vets = ref<Vet[]>([])

  async function getAll() {
    try {
      const response = await ApiService.get(
        '/public/veterinarians?page=1&pageSize=50',
      )
      vets.value = response.data.items
    } catch (error) {
      console.error('Failed to fetch veterinarians', error)
    }
    return vets.value
  }

  async function get(id: string) {
    try {
      const response = await ApiService.get(`/public/veterinarians/${id}`)
      return response.data
    } catch (error) {
      console.error('Failed to fetch veterinarian', error)
    }
  }

  async function getAvailabilityByVetId(
    id: string,
    page: number = 1,
    pageSize: number = 5,
  ) {
    try {
      const response = await ApiService.get(
        `/public/veterinarians/${id}/availability?page=${page}&pageSize=${pageSize}`,
      )
      return response.data
    } catch (error) {
      console.error('Failed to fetch veterinarian availability', error)
    }
  }

  async function getMeetings(
    page: number = 1,
    pageSize: number = 5,
    filter: string | null = null,
  ) {
    console.log(page)
    console.log(filter)
    try {
      let url = `/veterinarians/meetings?page=${page}&pageSize=${pageSize}&sortColumn=startTime&sortOrder=desc`

      if (filter !== null) {
        url += `&filter=${filter}`
      }

      const response = await ApiService.get(url)
      return response.data
    } catch (error) {
      console.error('Failed to fetch pet owner meetings', error)
    }
  }

  async function getMeeting(meetingId: string) {
    try {
      const response = await ApiService.get(
        `/veterinarians/meetings/${meetingId}`,
      )
      return response.data
    } catch (error) {
      console.error('Failed to fetch meeting', error)
    }
  }

  async function readyForMeeting(meetingId: string) {
    try {
      const response = await ApiService.post(
        `/veterinarians/ready-for-meeting`,
        {
          meetingId,
        },
      )
      return response.data
    } catch (error) {
      console.error('Failed to set meeting as ready', error)
    }
  }

  async function isPetOwnerReadyForMeeting(meetingId: string) {
    try {
      const response = await ApiService.get(
        `/veterinarians/ready-for-meeting/petowner/${meetingId}`,
      )
      console.log(response)
      return response
    } catch (error) {
      console.error('Failed to check if pet owner is ready for meeting', error)
    }
  }

  async function createNote(meetingId: string, meetingNote: string) {
    const response = await ApiService.post(`/veterinarians/meetings/notes`, {
      meetingId,
      meetingNote,
    })
    return response.data
  }

  async function getProfileDetails() {
    const response = await ApiService.get('/veterinarians/about')
    return response.data
  }

  return {
    vets,
    getAll,
    get,
    getAvailabilityByVetId,
    getMeetings,
    getMeeting,
    readyForMeeting,
    createNote,
    getProfileDetails,
    isPetOwnerReadyForMeeting,
  }
})
